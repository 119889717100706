import moment from "moment";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { SUBSCRIPTION_TYPES } from "../common/types/subscription-service";
import {
  clampDateRange,
  DATE_GRANULARITY,
  DateRange,
  demoDateBoundaries,
  FILTER_TYPES,
  getCompareDateRange,
  getDateRangeFromRelativeRange,
  getDemoDateDefaults,
  Granularity,
  PREFILTERS_VALUES,
  SmartDateComparePeriod,
} from "../utils/dateUtils";
import {
  getDateRangeFromSelector,
  SmartFilterSelector,
} from "../utils/filterUtils";

import { useBootstrap } from "./bootstrap";
import { useDatePickerMinMaxDate } from "./useDatePickerMinMaxDate";
import useLocalStorage from "./useLocalStorage";

export type AttributionModel =
  | "full_paid_overlap"
  | "first_click"
  | "last_click"
  | "linear"
  | "linear_paid"
  | "u_shaped"
  | "full_impact"
  | "time_decay"
  | "full_paid_overlap_fb_views";

export interface SmartFilterContextProps {
  range: DateRange;
  compareRange: DateRange;
  comparePeriod: SmartDateComparePeriod;
  granularity: Granularity | "none";
  granularityNoEntireRange: Granularity;
  matchingDayOfWeek: boolean;
  selector: SmartFilterSelector;
  stores: string[];
  timezone: string;
  selectedViewIds: string[];
  search: string;
  attributionModel: AttributionModel;
  benchmarkIndustry: string;
  setSearch: (value: string) => void;
  setCompareRange: (value: DateRange) => void;
  setComparePeriod: (value: SmartDateComparePeriod) => void;
  setGranularity: (value: Granularity | "none") => void;
  setSelector: (value: SmartFilterSelector) => void;
  setAttributionModel: (values: AttributionModel) => void;
  setBenchmarkIndustry: (values: string) => void;
  setStores: (values: string[]) => void;
  setTimezone: (value: string) => void;
  setMatchingDayOfWeek: (value: boolean) => void;
  setSelectedViewIds: (values: string[]) => void;
}

type FILTER_KEYS =
  | "default"
  | "subscriptions"
  | "retention"
  | "acquisition"
  | "products"
  | "inventoryPlanning"
  | "audience";

export const ALL_BENCHMARK_INDUSTRY = "All Industries";

const DEFAULT_VALUES: {
  default: SmartFilterContextProps;
} & { [Key in FILTER_KEYS]?: Partial<SmartFilterContextProps> } = {
  default: {
    matchingDayOfWeek: false,
    search: "",
    range: getDateRangeFromRelativeRange(
      {
        amount: 30,
        type: DATE_GRANULARITY.DAY,
      },
      false,
    ),
    compareRange: getCompareDateRange(
      getDateRangeFromRelativeRange(
        {
          amount: 30,
          type: DATE_GRANULARITY.DAY,
        },
        false,
      ),
      "previousPeriod",
    ),
    comparePeriod: "previousPeriod",
    granularity: DATE_GRANULARITY.DAY,
    granularityNoEntireRange: DATE_GRANULARITY.DAY,
    selector: {
      tab: FILTER_TYPES.RELATIVE,
      predefinedSelection: "yesterday",
      relativeSelection: {
        count: 30,
        granularity: DATE_GRANULARITY.DAY,
      },
      rangeSelection: getDateRangeFromRelativeRange(
        {
          amount: 30,
          type: DATE_GRANULARITY.DAY,
        },
        false,
      ),
    },
    attributionModel: "first_click",
    benchmarkIndustry: ALL_BENCHMARK_INDUSTRY,
    stores: [],
    selectedViewIds: [],
    timezone: "Europe/Paris",
    setSearch: () => {},
    setCompareRange: () => {},
    setComparePeriod: () => {},
    setGranularity: () => {},
    setMatchingDayOfWeek: () => {},
    setSelector: () => {},
    setStores: () => {},
    setBenchmarkIndustry: () => {},
    setAttributionModel: () => {},
    setTimezone: () => {},
    setSelectedViewIds: () => {},
  },
  retention: {
    range: getDateRangeFromRelativeRange(
      {
        amount: 12,
        type: DATE_GRANULARITY.MONTH,
      },
      false,
    ),
    compareRange: getCompareDateRange(
      getDateRangeFromRelativeRange(
        {
          amount: 12,
          type: DATE_GRANULARITY.MONTH,
        },
        false,
      ),
      "previousPeriod",
    ),
    granularity: DATE_GRANULARITY.MONTH,
    granularityNoEntireRange: DATE_GRANULARITY.MONTH,
    selector: {
      tab: FILTER_TYPES.RELATIVE,
      predefinedSelection: "yesterday",
      relativeSelection: {
        count: 12,
        granularity: DATE_GRANULARITY.MONTH,
      },
      rangeSelection: getDateRangeFromRelativeRange(
        {
          amount: 12,
          type: DATE_GRANULARITY.MONTH,
        },
        false,
      ),
    },
  },
  inventoryPlanning: {
    selector: {
      tab: FILTER_TYPES.RELATIVE,
      predefinedSelection: "yesterday",
      relativeSelection: {
        count: 60,
        granularity: DATE_GRANULARITY.DAY,
      },
      rangeSelection: getDateRangeFromRelativeRange(
        {
          amount: 60,
          type: DATE_GRANULARITY.DAY,
        },
        false,
      ),
    },
  },
  acquisition: undefined,
};

const SmartFilterContext = createContext<SmartFilterContextProps | null>(null);

export function ProvideSmartFilter({
  children,
  filterKey = "default",
}: {
  children: React.ReactNode;
  filterKey?: FILTER_KEYS;
}) {
  const smartFilter = useProvideSmartFilter(filterKey);
  return (
    <SmartFilterContext.Provider value={smartFilter}>
      {children}
    </SmartFilterContext.Provider>
  );
}

export function ProvideFilterOverrides({
  children,
  keyByElement = undefined,
  filterKey = "default",
}: {
  children: React.ReactNode;
  keyByElement?: Partial<Record<KeyProperties, FILTER_KEYS>>;
  filterKey?: FILTER_KEYS;
}) {
  const smartFilter = useProvideSmartFilter(filterKey, keyByElement);
  return (
    <SmartFilterContext.Provider value={smartFilter}>
      {children}
    </SmartFilterContext.Provider>
  );
}

export const useSmartFilter = () => {
  const context = useContext(SmartFilterContext);
  if (context === null) {
    throw Error("Smart filter context not provided");
  }
  return context;
};

type KeyProperties = keyof SmartFilterContextProps;

function useProvideSmartFilter(
  key: FILTER_KEYS,
  keyByElement?: Partial<Record<KeyProperties, FILTER_KEYS>>,
): SmartFilterContextProps {
  const {
    subscription,
    tenant: {
      settings: { timezone: tenantTimezone },
    },
    getUserTenantSetting,
    hasPermission,
  } = useBootstrap();
  const { minDate } = useDatePickerMinMaxDate();

  const maySeeTodayData = hasPermission("data.seeTodayData");

  const { isDemoData } = useBootstrap();
  const isFreePlan = subscription?.plan === SUBSCRIPTION_TYPES.FREE_PLAN;

  const defaultValuesForKey = {
    ...DEFAULT_VALUES.default,
    ...(DEFAULT_VALUES[key] ?? {}),
    // for demo data, we need to override the date ranges
    ...(isDemoData
      ? {
          range: getDemoDateDefaults(key),
          comparePeriod: "range" as SmartDateComparePeriod,
          compareRange: getDemoDateDefaults(key, true),
          selector: {
            tab: FILTER_TYPES.RANGE,
            predefinedSelection: PREFILTERS_VALUES.BFCM_2023,
            relativeSelection: {
              count: 30,
              granularity: DATE_GRANULARITY.DAY,
            },
            rangeSelection: getDemoDateDefaults(key),
          },
        }
      : {}),
  };
  const keyForElement = (property: KeyProperties) =>
    keyByElement?.[property] ?? key;

  const [search, setSearch] = useState("");

  const [stores, setStores] = useLocalStorage<string[]>(
    `smart-date-filter-${keyForElement("stores")}-stores`,
    [...defaultValuesForKey.stores],
  );

  const [timezone, setTimezone] = useLocalStorage<string>(
    `smart-date-filter-${keyForElement("timezone")}-timezone`,
    tenantTimezone || defaultValuesForKey.timezone,
  );

  const [selectedViewIds, setSelectedViewIds] = useLocalStorage<string[]>(
    `smart-date-filter-${keyForElement("selectedViewIds")}-views`,
    [...defaultValuesForKey.selectedViewIds],
  );

  const [attributionModel, setAttributionModel] =
    useLocalStorage<AttributionModel>(
      `smart-filter-${keyForElement("attributionModel")}-attribution-model`,
      defaultValuesForKey.attributionModel,
    );

  const [benchmarkIndustry, setBenchmarkIndustry] = useLocalStorage<string>(
    `smart-filter-${keyForElement("benchmarkIndustry")}-benchmark-industry`,
    defaultValuesForKey.benchmarkIndustry,
  );

  const [compareRange, setCompareRange] = useLocalStorage<DateRange>(
    `smart-date-filter-${keyForElement("compareRange")}-compare-range`,
    defaultValuesForKey.compareRange,
    (data) => ({
      start: moment(data.start),
      end: moment(data.end),
    }),
  );

  const [granularity, setGranularity] = useLocalStorage<Granularity | "none">(
    `smart-date-filter-${keyForElement("granularity")}-granularity`,
    defaultValuesForKey.granularity,
  );

  const [granularityNoEntireRange, setGranularityNoEntireRange] =
    useLocalStorage<Granularity>(
      `smart-date-filter-${keyForElement(
        "granularityNoEntireRange",
      )}-granularity-no-entire-range`,
      defaultValuesForKey.granularityNoEntireRange,
    );

  const [matchingDayOfWeek, setMatchingDayOfWeek] = useLocalStorage<boolean>(
    `smart-date-filter-${keyForElement(
      "matchingDayOfWeek",
    )}-matching-day-of-week`,
    defaultValuesForKey.matchingDayOfWeek,
  );

  const [comparePeriod, setComparePeriod] =
    useLocalStorage<SmartDateComparePeriod>(
      `smart-date-filter-${keyForElement("comparePeriod")}-compare-period`,
      defaultValuesForKey.comparePeriod,
    );

  const [selector, setSelector] = useLocalStorage<SmartFilterSelector>(
    `smart-date-filter-${keyForElement("selector")}-selector`,
    defaultValuesForKey.selector,
    (data) => ({
      ...data,
      rangeSelection: {
        start: moment(data.rangeSelection.start),
        end: moment(data.rangeSelection.end),
      },
    }),
  );
  const getClampedDateRange = useCallback(
    (dateRange: DateRange) => {
      if (isDemoData) {
        return clampDateRange(demoDateBoundaries, dateRange);
      }
      return dateRange;
    },
    [isDemoData],
  );
  const handleSetCompareRange = (value: DateRange) => {
    setCompareRange(getClampedDateRange(value));
  };
  const handleSetSelector = (value: SmartFilterSelector) => {
    setSelector({
      ...value,
      rangeSelection: getClampedDateRange(value.rangeSelection),
    });
  };
  // IMPORTANT: need to re-calculate rangeSelection when using relative or predefined date range:
  // e.g. this month, last 30 days, etc.
  const recalculatedRange =
    selector.tab !== FILTER_TYPES.RANGE
      ? getDateRangeFromSelector(
          getUserTenantSetting("weekstart", "sunday") === "sunday" ? 0 : 1,
          selector,
          maySeeTodayData,
          minDate?.toISOString(),
        )
      : selector.rangeSelection;
  const recalculatedCompareRange = getCompareDateRange(
    recalculatedRange,
    comparePeriod,
    compareRange,
    matchingDayOfWeek,
    granularity,
  );
  useEffect(() => {
    if (
      recalculatedRange.end.unix() !== selector.rangeSelection.end.unix() ||
      recalculatedRange.start.unix() !== selector.rangeSelection.start.unix()
    ) {
      handleSetSelector({
        ...selector,
        rangeSelection: recalculatedRange,
      });
    }
    // eslint-disable-next-line
  }, [recalculatedRange.end.unix(), recalculatedRange.start.unix()]);
  useEffect(() => {
    if (
      recalculatedCompareRange.end.unix() !== compareRange.end.unix() ||
      recalculatedCompareRange.start.unix() !== compareRange.start.unix()
    ) {
      handleSetCompareRange(recalculatedCompareRange);
    }
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    recalculatedCompareRange.end.unix(),
    // eslint-disable-next-line
    recalculatedCompareRange.start.unix(),
  ]);

  const handleSetMatchingDayOfWeek = (value: boolean) =>
    setMatchingDayOfWeek(value);

  const handleSetComparePeriod = (value: SmartDateComparePeriod) =>
    setComparePeriod(value);

  const handleSetGranularity = (value: Granularity | "none") => {
    if (value !== "none") {
      setGranularityNoEntireRange(value);
    }
    setGranularity(value);
  };

  const handleSetStores = (value: string[]) => setStores(value);
  const handleSetViews = (value: string[]) => setSelectedViewIds(value);
  const handleSetTimezone = (value: string) => setTimezone(value);

  return {
    // deprecated, please use selector.rangeSelection instead
    range: selector.rangeSelection,
    compareRange,
    comparePeriod,
    granularity,
    granularityNoEntireRange,
    selector,
    matchingDayOfWeek,
    search,
    attributionModel,
    benchmarkIndustry,
    stores,
    selectedViewIds: isFreePlan ? [] : selectedViewIds,
    timezone,
    setCompareRange: handleSetCompareRange,
    setComparePeriod: handleSetComparePeriod,
    setGranularity: handleSetGranularity,
    setSelector: handleSetSelector,
    setMatchingDayOfWeek: handleSetMatchingDayOfWeek,
    setSearch: (v: string) => setSearch(v),
    setAttributionModel,
    setBenchmarkIndustry,
    setStores: handleSetStores,
    setSelectedViewIds: handleSetViews,
    setTimezone: handleSetTimezone,
  };
}
