import {
  NotificationMessage,
  Space,
  Text,
  theme,
} from "../../../../icecube-ux";
import { _, _node } from "../../../../languages/helper";

const { primary110, text110, primary100 } = theme.colors;

export const CriteoConnectMessage = () => (
  <>
    <NotificationMessage
      variant="long"
      style={{ alignItems: "start", padding: "12px" }}
      textContent={
        <>
          <Text variant="body12Regular" color={text110}>
            <Text variant="body12SemiBold" color={primary110}>
              {_`Important`}
            </Text>
            <Text variant="body12Regular" color={text110} inline>
              {_node`If you have an error while connecting your Criteo account to the app, follow the steps detailed ${[
                "link",
                <a
                  href="https://intercom.help/polar-app/en/articles/5789375-how-can-i-connect-criteo-to-my-account"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Intercom help"
                >
                  <Text
                    variant="body12Regular"
                    color={primary100}
                    inline
                    underline
                  >
                    {_`here|||link`}
                  </Text>
                </a>,
              ]}.`}
            </Text>
          </Text>
        </>
      }
    />
    <Space />
  </>
);
