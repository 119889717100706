import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { RechargeConnectMessage } from "../pages/connectors/popups/connect/RechargeConnectMessage";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";

const openTab = (e: React.MouseEvent<HTMLAnchorElement>) => {
  e.preventDefault();
  e.stopPropagation();
  window
    .open(
      "https://intercom.help/polar-app/en/articles/5814352-how-can-i-connect-recharge-to-my-account",
      "_blank",
    )
    ?.focus();
};

registerFrontendIntegration({
  key: "recharge",
  category: INTEGRATION_CATEGORIES.sales_channels,
  dependsOnAccounts: true,
  image: "Recharge",
  isNewIntegrationService: true,
  backgroundColor: theme.customColors.rechargeBg,
  borderColor: theme.customColors.rechargeBorder,
  label: "Recharge",
  get description() {
    return _`Recurring Billing, Subscription for Ecommerce`;
  },
  setupInstructionsSection: RechargeConnectMessage,
  paramsDescriptionOverride: {
    api_key: (
      <>
        {_`To learn how to find your API key, please read`}{" "}
        <a href="/#" onClick={openTab}>
          {_`this article`}
        </a>
      </>
    ),
  },
  provider: "fivetran",
});
