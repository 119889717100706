import { isCancelledError } from "@tanstack/query-core";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";

import { compose } from "../lib/synthService";
import {
  ComposeData,
  ComposeDataBreakdown,
  ComposeDataElement,
} from "../types/composeData";
import { ComposeExtraParams, ComposeTopFilter } from "../types/synthesizer";
import {
  ComposeRule,
  ComposeRuleElement,
  ComposeRules,
} from "../utils/composeUtils";
import {
  DateRange,
  getCompareDateRange,
  Granularity,
  SmartDateComparePeriod,
} from "../utils/dateUtils";
import { isRuleGroup } from "../utils/filterUtils";
import {
  IMMEDIATE,
  queryDispatcher,
  QueryDispatcherPriority,
} from "../utils/queryDispatcher";
import {
  EMPTY_ARRAY,
  filterIsNotNullOrUndefined,
  ucFirst,
} from "../utils/utils";

import { useAuth } from "./auth/auth";
import { useBootstrap } from "./bootstrap";
import useDebouncedEffect from "./useDebounceEffect";
import {
  ComposeFinishedEvent,
  PerformanceEvent,
  trackPageLoadCheckpoint,
  usePerformanceMeasurement,
} from "./usePerformanceMeasurement";

type ComposeResultType = "table" | "totals";
type ComposePeriodType = "actual" | "compare";
export type ComposeCompareSettings = {
  displayValue: boolean;
  displayPercentage: boolean;
  metrics: string[];
  compareRange?: DateRange;
  period: SmartDateComparePeriod;
  matchingDaysOfWeek?: boolean;
};
type UseComposeOptions = {
  skipAll?: boolean;
  skipActualTableData?: boolean;
  skipCompareTableData?: boolean;
  skipActualTotalData?: boolean;
  skipCompareTotalData?: boolean;
};
type DataSourceQueryRef = {
  tableData: string;
  totalData: string;
  compareTableData: string;
  compareTotalData: string;
};
type DataSourceQueryRefKeys = keyof DataSourceQueryRef;
type UseComposeOptionsKeys = keyof UseComposeOptions;
type BatchInfo = {
  // primary shall be the fastest batch to load in order to display some data to the user
  // result of the batch would be joined by with the primary batch's breakdowns
  isPrimary: boolean;
  metrics: string[];
};

export interface UseComposeProps {
  granularity: Granularity | "none";
  metrics: string[];
  ordering: string;
  direction: "ASC" | "DESC";
  range?: DateRange;
  views?: string[];
  breakdowns?: string[];
  rules: ComposeRules;
  metricRules?: ComposeRules;
  compareSettings?: ComposeCompareSettings;
  loaderIndex?: number;
  wait?: boolean;
  requestPriorities?: {
    actualTable?: QueryDispatcherPriority;
    actualTotals?: QueryDispatcherPriority;
    compareTable?: QueryDispatcherPriority;
    compareTotals?: QueryDispatcherPriority;
  };
  limit?: number;
  top?: ComposeTopFilter;
  extraParams?: ComposeExtraParams;
  options?: UseComposeOptions;
  cacheKey?: string;
  enableBatchLoading?: boolean;
  getBatches?: (metrics: string[]) => BatchInfo[];
}

type PriorityUpdaterKey =
  `set${Capitalize<ComposePeriodType>}${Capitalize<ComposeResultType>}Priority`;
export type PriorityUpdaters = {
  [kind in PriorityUpdaterKey]?: (priority: QueryDispatcherPriority) => void;
};
const requestPriorityKeys = [
  "actualTotals",
  "actualTable",
  "compareTable",
  "compareTotals",
] as const;

const getPriorityUpdaterKey = ({
  resultType,
  periodType,
}: {
  resultType: ComposeResultType;
  periodType: ComposePeriodType;
}): PriorityUpdaterKey => {
  return `set${ucFirst(periodType) as Capitalize<ComposePeriodType>}${
    ucFirst(resultType) as Capitalize<ComposeResultType>
  }Priority`;
};
const joinKeyBuilder = (
  data: Record<string, string | number>,
  breakdowns: string[],
) => {
  return breakdowns.map((breakdown) => data[breakdown]).join("-");
};

type ComposeWrapperArgs = UseComposeProps & {
  token: string;
  comparePeriod: DateRange;
  compareSettings?: ComposeCompareSettings;
  flags: string[];
  setError: (val: boolean) => void;
};

// full join the batches by using primary batch's breakdown ordering
// if missing data in the sub batches, the metrics will be filled with null
const mergeDataByPrimaryBatch = <T>(
  allBatches: {
    batchInfo: BatchInfo;
    res: T[];
  }[],
  breakdowns: string[],
  options?: {
    fullJoin?: boolean;
  },
) => {
  let primaryMap: Map<string, T> = new Map();
  let primaryIdx = -1;
  const dataToMergeMap = allBatches.reduce<Map<string, Map<string, T>>>(
    (acc, batch, idx) => {
      const currentMap = batch.res.reduce<Map<string, T>>((batchMap, row) => {
        batchMap.set(
          joinKeyBuilder(row as Record<string, string | number>, breakdowns),
          row,
        );

        return batchMap;
      }, new Map<string, T>());
      acc.set(`${idx}`, currentMap);
      if (batch.batchInfo.isPrimary) {
        primaryMap = currentMap;
        primaryIdx = idx;
      }
      return acc;
    },
    new Map<string, Map<string, T>>(),
  );
  let addedMetrics = new Set<string>(allBatches[primaryIdx].batchInfo.metrics);

  for (let i = 0; i < allBatches.length; i++) {
    if (i === primaryIdx) {
      continue;
    }
    const batchInfo = allBatches[i].batchInfo;
    const subData = dataToMergeMap.get(`${i}`);
    for (const [key, value] of primaryMap.entries()) {
      if (!subData?.has(key)) {
        primaryMap.set(
          key,
          Object.assign(
            value as object,
            Object.fromEntries(batchInfo.metrics.map((v) => [v, null])),
          ) as T,
        );
      } else {
        const subValue = subData.get(key);
        primaryMap.set(key, Object.assign(value as object, subValue) as T);
      }
    }
    if (options?.fullJoin && subData) {
      for (const [key, value] of subData.entries()) {
        if (!primaryMap.has(key)) {
          primaryMap.set(
            key,
            // FULL JOIN for the missing data not in primary batch but in the sub batch
            // here we add the missing line which exist in the non-primary batch
            // we need to attach all the metrics which is added from other batches
            // we use addedMetrics to keep tracking of the metrics which is added from other batches
            Object.assign(
              Object.fromEntries([...addedMetrics].map((v) => [v, null])),
              value as object,
            ) as T,
          );
        }
      }
    }
    addedMetrics = new Set([...addedMetrics, ...batchInfo.metrics]);
  }
  return [...primaryMap.values()];
};
export const createComposeWrapper = ({
  token,
  metrics,
  compareSettings,
  comparePeriod,
  range,
  granularity,
  breakdowns = EMPTY_ARRAY,
  rules,
  metricRules,
  ordering,
  direction,
  views,
  flags,
  limit,
  top,
  extraParams,
  cacheKey,
  setError,
  enableBatchLoading,
  getBatches,
}: ComposeWrapperArgs) => {
  return {
    getDataLoader:
      <T>({
        abortSignal,
        resultType,
        isCompare,
        periodType,
        setLoading,
        setData,
        priorityUpdatersRef,
        performanceEvent,
        priority,
        measurement,
      }: {
        abortSignal?: AbortSignal;
        resultType: ComposeResultType;
        isCompare?: boolean;
        periodType: ComposePeriodType;
        setLoading: (val: boolean) => void;
        setData: (val: T[]) => Promise<void> | void;
        priorityUpdatersRef: React.MutableRefObject<PriorityUpdaters>;
        performanceEvent: ComposeFinishedEvent;
        priority?: QueryDispatcherPriority;
        measurement: ReturnType<typeof usePerformanceMeasurement>;
      }) =>
      async () => {
        if (enableBatchLoading) {
          if (!getBatches) {
            throw new Error(
              "getBatch is required when enableBatchLoading is set to true",
            );
          }
        }
        let metricList = metrics;
        let period = range;
        if (periodType === "compare") {
          metricList = compareSettings?.metrics || EMPTY_ARRAY;
          period = comparePeriod;
        }
        if (!priority || metricList.length === 0) {
          await setData([]);
          return;
        }

        setError(false);
        setLoading(true);
        const getDispatchInfo = (metrics: string[]) => {
          return queryDispatcher.dispatch(
            async () => {
              return await compose<T>(
                abortSignal,
                token,
                metrics,
                period,
                granularity,
                breakdowns,
                rules,
                isCompare ? undefined : metricRules,
                ordering,
                direction === "ASC",
                views,
                flags,
                limit,
                isCompare ? undefined : top,
                resultType === "totals",
                extraParams,
                measurement?.traceId,
                cacheKey,
              );
            },
            { priority },
          );
        };
        if (enableBatchLoading) {
          const dispatchInfoPromises: Promise<{
            res: T[];
            batchInfo: BatchInfo;
          }>[] = [];
          const batches = getBatches?.(metricList) ?? [];
          for (let i = 0; i < batches.length; i++) {
            const batch = batches[i];
            const dispatchInfo = getDispatchInfo(batch.metrics);
            if (batch.isPrimary) {
              const key = getPriorityUpdaterKey({ resultType, periodType });
              priorityUpdatersRef.current = {
                ...priorityUpdatersRef.current,
                [key]: dispatchInfo.updatePriority,
              };
              dispatchInfoPromises.push(
                dispatchInfo.response
                  .then(async (res) => {
                    await setData(res);
                    return {
                      res,
                      batchInfo: batch,
                    };
                  })
                  .finally(() => {
                    // IMPORTANT: set here the loading status to "false"
                    // This indicates that the primary batch is loaded => display the primary batch's data to the user
                    // (assuming that the primary batch is the fastest batch to load)
                    setLoading(false);
                  }),
              );
            } else {
              dispatchInfoPromises.push(
                dispatchInfo.response.then((res) => ({
                  res,
                  batchInfo: batch,
                })),
              );
            }
          }
          const allBatches = await Promise.all(dispatchInfoPromises);
          const mergedData = mergeDataByPrimaryBatch(allBatches, breakdowns);
          setLoading(false);
          await setData(mergedData);
          measurement?.reportTimeElapsed({ performanceEvent });
        } else {
          const dispatchInfo = getDispatchInfo(metricList);
          const key = getPriorityUpdaterKey({ resultType, periodType });
          priorityUpdatersRef.current = {
            ...priorityUpdatersRef.current,
            [key]: dispatchInfo.updatePriority,
          };
          await setData(await dispatchInfo.response);
          setLoading(false);
          measurement?.reportTimeElapsed({ performanceEvent });
        }
      },
  };
};

const isCompleteRule = (rule: ComposeRule): boolean => {
  return rule.operator && rule.value.length > 0;
};

const filterCompleteRuleElements = (
  rules: ComposeRuleElement[],
): ComposeRuleElement[] => {
  return rules
    .map((rule) => {
      if (isRuleGroup(rule)) {
        const filtered = rule.filter(isCompleteRule);
        return filtered.length === 0 ? undefined : filtered;
      } else {
        return isCompleteRule(rule) ? rule : undefined;
      }
    })
    .filter(filterIsNotNullOrUndefined);
};

export const filterCompleteRules = (inputRules: ComposeRules) => {
  return Object.fromEntries(
    Object.entries(inputRules)
      .map(([key, rules]) => {
        return [key, filterCompleteRuleElements(rules)];
      })
      .filter(([, rules]) => rules.length > 0),
  ) as ComposeRules;
};

const shouldReloadData = ({
  payloadHash,
  prevPayloadHash,
  skipOption,
}: {
  payloadHash: string;
  prevPayloadHash: string | undefined;
  skipOption: boolean | undefined;
}) => {
  if (skipOption === true) {
    return false;
  }
  return payloadHash !== prevPayloadHash;
};
export default function useCompose<MetricData = ComposeDataElement>({
  granularity,
  metrics,
  ordering,
  direction,
  range,
  breakdowns = [],
  rules: inputRules,
  metricRules,
  compareSettings,
  views = [],
  loaderIndex = 0,
  wait,
  requestPriorities = {
    actualTable: IMMEDIATE,
    actualTotals: IMMEDIATE,
  },
  limit,
  top,
  extraParams,
  options = {},
  cacheKey,
  enableBatchLoading,
  getBatches,
}: UseComposeProps) {
  const auth = useAuth();
  const { getUserTenantSetting } = useBootstrap();
  const rules = filterCompleteRules(inputRules);

  const include0Orders = getUserTenantSetting(
    "revenue_computation_include_0_orders",
    0,
  );
  const flags = include0Orders ? [] : ["excludeZeroOrder"];

  const comparePeriodFromSettings = useMemo<DateRange>(() => {
    if (!compareSettings || !range) {
      return { start: moment(), end: moment() };
    }

    if (compareSettings.period === "range") {
      return compareSettings.compareRange || { start: moment(), end: moment() };
    }

    return getCompareDateRange(
      range,
      compareSettings.period,
      compareSettings.compareRange,
      compareSettings.matchingDaysOfWeek,
      granularity,
    );
  }, [compareSettings, range, granularity]);
  // Changes to this payload will cause new requests
  const requestPayload = {
    rules,
    metricRules,
    metrics,
    breakdowns,
    range,
    granularity,
    ordering,
    direction,
    loaderIndex,
    compareSettings,
    views,
    flags,
    limit,
    top,
    extraParams,
    comparePeriodFromSettings,
    cacheKey,
  };
  const payloadHash = JSON.stringify(requestPayload);
  const optionsHash = JSON.stringify(options);
  const prevDataQueryHashRef = useRef<DataSourceQueryRef>({
    tableData: "",
    totalData: "",
    compareTableData: "",
    compareTotalData: "",
  });
  const prevPayloadHashRef = useRef<string>();
  const prevPriorities = useRef<UseComposeProps["requestPriorities"]>();

  const priorityUpdatersRef = useRef<PriorityUpdaters>({});

  const measurements: Record<
    keyof NonNullable<UseComposeProps["requestPriorities"]>,
    ReturnType<typeof usePerformanceMeasurement>
  > = {
    actualTable: { traceId: "", reportTimeElapsed: () => {} },
    actualTotals: { traceId: "", reportTimeElapsed: () => {} },
    compareTable: { traceId: "", reportTimeElapsed: () => {} },
    compareTotals: { traceId: "", reportTimeElapsed: () => {} },
  };

  for (const key of requestPriorityKeys) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    measurements[key] = usePerformanceMeasurement({
      eventRecord: requestPriorities[key]
        ? {
            performanceEvent: PerformanceEvent.USE_COMPOSE_STARTED,
            compose: requestPayload,
          }
        : undefined,
    });
  }

  trackPageLoadCheckpoint(PerformanceEvent.PAGE_LOAD_CHECKPOINT);

  const [loadingData, setLoadingData] = useState(
    Boolean(requestPriorities.actualTable),
  );
  const [loadingTotal, setLoadingTotal] = useState(
    Boolean(requestPriorities.actualTotals),
  );
  type ResultData = ComposeDataBreakdown & MetricData;
  const [loadingCompare, setLoadingCompare] = useState(false);
  const [loadingCompareTotal, setLoadingCompareTotal] = useState(false);

  const [error, setError] = useState(false);
  const [rowsCount, setRowsCount] = useState(0);
  const [tableData, setTableData] = useState<Array<ResultData>>([]);
  const [totalData, setTotalData] = useState<Array<MetricData>>([]);
  const [compareTableData, setCompareTableData] = useState<ComposeData>([]);
  const [compareTotalData, setCompareTotalData] = useState<ComposeData>([]);
  useDebouncedEffect(
    () => {
      if (wait || auth.processing || !auth.isLoggedIn) {
        setLoadingData(Boolean(requestPriorities.actualTable));
        setLoadingTotal(Boolean(requestPriorities.actualTotals));
        return;
      }

      const loadMetrics = async (abortSignal: AbortSignal) => {
        const composeWrapper = createComposeWrapper({
          token: await auth.getToken(),
          metrics,
          range,
          comparePeriod: comparePeriodFromSettings,
          compareSettings,
          granularity,
          breakdowns,
          rules,
          metricRules,
          ordering,
          direction,
          views,
          flags,
          top,
          limit,
          extraParams,
          cacheKey,
          setError,
          enableBatchLoading,
          getBatches,
        });
        const shouldReload = (
          optionKey: UseComposeOptionsKeys,
          dataSourceName: DataSourceQueryRefKeys,
        ) =>
          shouldReloadData({
            payloadHash,
            prevPayloadHash: prevDataQueryHashRef.current[dataSourceName],
            skipOption: options?.[optionKey] || options?.skipAll,
          });
        const loadTotals = shouldReload("skipActualTotalData", "totalData")
          ? composeWrapper.getDataLoader<MetricData>({
              abortSignal,
              resultType: "totals",
              periodType: "actual",
              setLoading: setLoadingTotal,
              setData: (data) => {
                setTotalData(data);
                prevDataQueryHashRef.current.totalData = payloadHash;
              },
              performanceEvent: PerformanceEvent.USE_COMPOSE_TOTAL_DATA_READY,
              measurement: measurements.actualTotals,
              priority: requestPriorities.actualTotals,
              priorityUpdatersRef,
            })
          : () => new Promise<void>((resolve) => resolve());
        const loadCompareTotals = shouldReload(
          "skipCompareTotalData",
          "compareTotalData",
        )
          ? composeWrapper.getDataLoader<ComposeDataElement>({
              abortSignal,
              isCompare: true,
              resultType: "totals",
              periodType: "compare",
              setLoading: setLoadingCompareTotal,
              setData: (data) => {
                setCompareTotalData(data);
                prevDataQueryHashRef.current.compareTotalData = payloadHash;
              },
              performanceEvent:
                PerformanceEvent.USE_COMPOSE_COMPARE_TOTAL_DATA_READY,
              measurement: measurements.compareTotals,
              priority: requestPriorities.compareTotals,
              priorityUpdatersRef,
            })
          : () => new Promise<void>((resolve) => resolve());
        const loadTable = shouldReload("skipActualTableData", "tableData")
          ? composeWrapper.getDataLoader<ResultData>({
              abortSignal,
              resultType: "table",
              periodType: "actual",
              setLoading: setLoadingData,
              setData: (data) => {
                setTableData(data);
                setRowsCount(data.length);
                prevDataQueryHashRef.current.tableData = payloadHash;
              },
              performanceEvent: PerformanceEvent.USE_COMPOSE_TABLE_DATA_READY,
              measurement: measurements.actualTable,
              priority: requestPriorities.actualTable,
              priorityUpdatersRef,
            })
          : () => new Promise<void>((resolve) => resolve());
        const loadCompareTable = shouldReload(
          "skipCompareTableData",
          "compareTableData",
        )
          ? composeWrapper.getDataLoader<ComposeDataElement>({
              abortSignal,
              isCompare: true,
              resultType: "table",
              periodType: "compare",
              setLoading: setLoadingCompare,
              setData: (data) => {
                setCompareTableData(data);
                prevDataQueryHashRef.current.compareTableData = payloadHash;
              },
              performanceEvent:
                PerformanceEvent.USE_COMPOSE_COMPARE_TABLE_DATA_READY,
              measurement: measurements.compareTable,
              priority: requestPriorities.compareTable,
              priorityUpdatersRef,
            })
          : () => new Promise<void>((resolve) => resolve());

        const handleErrors =
          (setLoading: (val: boolean) => void) => (e: unknown) => {
            if (
              !isCancelledError(e) &&
              `${e as string}` !== "Error: The user aborted a request." &&
              `${e as string}` !== "Error: signal is aborted without reason"
            ) {
              console.warn(e);
              setError(true);
            }
            setLoading(false);
          };

        await Promise.all([
          loadTotals().catch(handleErrors(setLoadingTotal)),
          loadCompareTotals().catch(handleErrors(setLoadingCompareTotal)),
          loadTable().catch(handleErrors(setLoadingData)),
          loadCompareTable().catch(handleErrors(setLoadingCompare)),
        ]);
        prevPayloadHashRef.current = payloadHash;
      };
      const abortController = new AbortController();
      void loadMetrics(abortController.signal);
      return () => {
        abortController.abort();
      };
    },
    500,
    [auth.isLoggedIn, auth.processing, wait, payloadHash, optionsHash],
  );

  useEffect(() => {
    if (!prevPriorities.current) {
      return;
    }

    if (requestPriorities.actualTable) {
      priorityUpdatersRef.current.setActualTablePriority?.(
        requestPriorities.actualTable,
      );
    }

    if (requestPriorities.actualTotals) {
      priorityUpdatersRef.current.setActualTotalsPriority?.(
        requestPriorities.actualTotals,
      );
    }

    if (requestPriorities.compareTable) {
      priorityUpdatersRef.current.setCompareTablePriority?.(
        requestPriorities.compareTable,
      );
    }

    if (requestPriorities.compareTotals) {
      priorityUpdatersRef.current.setCompareTotalsPriority?.(
        requestPriorities.compareTotals,
      );
    }
  }, [
    requestPriorities,
    priorityUpdatersRef.current.setActualTablePriority,
    priorityUpdatersRef.current.setActualTotalsPriority,
    priorityUpdatersRef.current.setCompareTablePriority,
    priorityUpdatersRef.current.setCompareTotalsPriority,
  ]);

  useEffect(() => {
    prevPriorities.current = requestPriorities;
  }, [prevPriorities, requestPriorities]);

  return {
    loadingData,
    loadingTotal,
    loadingCompare,
    loadingCompareTotal,
    error,
    rowsCount,
    tableData,
    totalData,
    compareTableData,
    compareTotalData,
    compareRange: comparePeriodFromSettings,
  };
}
