import classnames from "classnames";
import moment from "moment";
import { CSSProperties, ReactNode, useEffect, useMemo, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import styled from "styled-components";

import { useBootstrap } from "../../hooks/bootstrap";
import { useAddonPopup } from "../../hooks/useAddonPopup";
import { useTenantAddOns } from "../../hooks/useAddonStatuses";
import { useShowNewPricingComponents } from "../../hooks/useAddonUtils";
import { useDatePickerMinMaxDate } from "../../hooks/useDatePickerMinMaxDate";
import {
  Block,
  Button,
  Calendar,
  Chip,
  Grid,
  Icon,
  Input,
  Scroller,
  Select,
  Text,
  legacyTheme,
  theme,
  Flex,
} from "../../icecube-ux";
import { _ } from "../../languages/helper";
import { AddonTag } from "../../shared/Plans/analyzeEnrichPlan/AddonTag";
import PlansPopup from "../../shared/Popups/PlansPopup";
import {
  DateRange,
  DATE_GRANULARITY_OPTIONS,
  FILTER_TYPES,
  getCompareDateRange,
  getDateRangeFromPeriod,
  getDateRangeFromRelativeRange,
  Granularity,
  PREFILTERS,
  PREFILTERS_VALUES,
  SmartDateComparePeriod,
  PREFILTERS_COMPARE_VALUES,
  shouldWarnTooManyDatapoints,
  DatePickerTabType,
  PrefilterType,
} from "../../utils/dateUtils";
import { SmartFilterSelector } from "../../utils/filterUtils";
import { pluralize } from "../../utils/languageUtils";
import { isFreeOrTrialPlan } from "../../utils/subscriptionsUtils";
import TabButtons from "../TabButtons/TabButtons";

import ComparisonTypeSelector from "./ComparisonTypeSelector";

interface SmartDateFilterPickerProps {
  onApply?: (
    compareRange: DateRange,
    selector: SmartFilterSelector,
    comparePeriod: SmartDateComparePeriod,
    matchingDayOfWeek: boolean,
  ) => void;
  onClose: () => void;
  allowCompare?: boolean;
  asPopup?: boolean;
  granularity: Granularity | "none";
  compareRange: DateRange;
  comparePeriod: SmartDateComparePeriod;
  matchingDayOfWeek: boolean;
  selector: SmartFilterSelector;
  setCompareRange: (value: DateRange) => void;
  setComparePeriod: (value: SmartDateComparePeriod) => void;
  setSelector: (value: SmartFilterSelector) => void;
  setMatchingDayOfWeek: (value: boolean) => void;
  mobileVersion?: boolean;
  noMaxHeight?: boolean;
  maxHeight?: string;
  wrapperStyle?: CSSProperties;
  additionalFooterActions?: ReactNode;
  footerInfoComponent?: ReactNode;
  forcedMaxDate?: moment.Moment;
  forcedMinDate?: moment.Moment;
}

const FooterInfo = styled(Flex)`
  border-top: 1px solid ${theme.colors.borderLight};
  margin-top: 12px;
  padding: 12px 12px 0 12px;
`;

export default function SmartDateFilterPicker({
  onApply,
  onClose,
  allowCompare,
  asPopup = true,
  granularity,
  matchingDayOfWeek,
  compareRange,
  comparePeriod,
  selector,
  setCompareRange,
  setSelector,
  setComparePeriod,
  setMatchingDayOfWeek,
  mobileVersion,
  noMaxHeight,
  maxHeight,
  wrapperStyle,
  additionalFooterActions,
  footerInfoComponent,
  forcedMaxDate,
  forcedMinDate,
}: SmartDateFilterPickerProps) {
  const { maxDate, minDate } = useDatePickerMinMaxDate();
  const maxDateToUse = forcedMaxDate || maxDate;
  const minDateToUse = forcedMinDate || minDate;
  const { setAddon } = useAddonPopup();
  const { isUserEligibleToSeeNewPricing } = useShowNewPricingComponents();
  const { isHourlyRefreshEnabled } = useTenantAddOns();

  const { subscription, isDemoData, getUserTenantSetting, hasPermission } =
    useBootstrap();
  const maySeeTodayData = hasPermission("data.seeTodayData");

  const [isShowingPlans, setIsShowingPlans] = useState(false);
  const [newMatchingDayOfWeek, setNewMatchingDayOfWeek] = useState(false);
  const [newCompareRange, setNewCompareRange] = useState<DateRange>({
    ...compareRange,
  });
  const [newComparePeriod, setNewComparePeriod] =
    useState<SmartDateComparePeriod>(comparePeriod);
  const [newSelector, setNewSelector] = useState<SmartFilterSelector>({
    ...selector,
  });

  const isFreeOrTrial = isFreeOrTrialPlan(subscription);

  const areDatesOutsideOfBounds = useMemo(() => {
    return (
      (minDateToUse &&
        (newSelector.rangeSelection.start.isBefore(minDateToUse) ||
          (allowCompare && newCompareRange.start.isBefore(minDateToUse)))) ||
      (maxDateToUse && newSelector.rangeSelection.end.isAfter(maxDateToUse))
    );
  }, [
    allowCompare,
    minDateToUse,
    newSelector.rangeSelection,
    newCompareRange,
    maxDateToUse,
  ]);

  const handleTabChange = (v: string | number) => {
    setNewSelector((s) => ({
      ...s,
      tab: v as DatePickerTabType,
    }));
    if (v === FILTER_TYPES.PREDEFINED) {
      handleClickOnPrefilter(newSelector.predefinedSelection);
    } else if (v === FILTER_TYPES.RELATIVE) {
      handleRelativeFilterChange(
        newSelector.relativeSelection.count,
        newSelector.relativeSelection.granularity,
      );
    }
  };

  const handleClickOnPrefilter = (filterValue: PrefilterType) => {
    const defaultRangeForTab = getDateRangeFromPeriod(
      filterValue,
      getUserTenantSetting("weekstart", "sunday") === "sunday" ? 0 : 1,
      maySeeTodayData,
      minDateToUse?.toISOString(),
    );
    if (newComparePeriod === PREFILTERS_COMPARE_VALUES.PREVIOUS_PERIOD) {
      setNewMatchingDayOfWeek(false);
    }
    setNewCompareRange(
      getCompareDateRange(
        defaultRangeForTab,
        comparePeriod,
        newCompareRange,
        matchingDayOfWeek,
        granularity,
      ),
    );
    setNewSelector((s) => ({
      ...s,
      tab: FILTER_TYPES.PREDEFINED,
      predefinedSelection: filterValue,
      rangeSelection: defaultRangeForTab,
    }));
  };

  const handleRelativeFilterChange = (
    amount: number,
    granularity: Granularity,
  ) => {
    const range = getDateRangeFromRelativeRange(
      {
        amount,
        type: granularity,
      },
      false,
    );
    setNewCompareRange(
      getCompareDateRange(
        range,
        newComparePeriod,
        newCompareRange,
        newMatchingDayOfWeek,
        granularity,
      ),
    );
    setNewSelector((s) => ({
      ...s,
      tab: FILTER_TYPES.RELATIVE,
      relativeSelection: {
        count: amount,
        granularity,
      },
      rangeSelection: range,
    }));
  };

  const handleApply = () => {
    setCompareRange({ ...newCompareRange });
    setSelector({
      ...newSelector,
      rangeSelection: {
        start: newSelector.rangeSelection.start.clone(),
        end: newSelector.rangeSelection.end.clone(),
      },
    });
    setComparePeriod(newComparePeriod);
    setMatchingDayOfWeek(newMatchingDayOfWeek);
    onApply?.(
      newCompareRange,
      newSelector,
      newComparePeriod,
      newMatchingDayOfWeek,
    );
    onClose();
  };
  const startUnix = compareRange.start.unix();
  const endUnix = compareRange.end.unix();
  useEffect(() => {
    setNewCompareRange({ ...compareRange });
    setNewComparePeriod(comparePeriod);
    setNewSelector({ ...selector });
    setNewMatchingDayOfWeek(matchingDayOfWeek);
    // eslint-disable-next-line
  }, [startUnix, endUnix, comparePeriod, selector, matchingDayOfWeek]);

  const warnTooManyDatapoints = shouldWarnTooManyDatapoints(
    newSelector.rangeSelection,
    granularity,
  );
  return (
    <OutsideClickHandler onOutsideClick={() => onClose()}>
      {isShowingPlans ? (
        <PlansPopup onClose={() => setIsShowingPlans(false)} />
      ) : null}

      <div
        data-cy="calendar-popup"
        className={classnames("smart-date-popup", { asPopup })}
        style={wrapperStyle}
      >
        <TabButtons
          value={newSelector.tab}
          onChange={(v) => handleTabChange(v)}
          withoutMargin
          tabs={[
            { label: _`Quick filters`, value: FILTER_TYPES.PREDEFINED },
            {
              label: _`Relative filters`,
              value: FILTER_TYPES.RELATIVE,
            },
            { label: _`Date range`, value: FILTER_TYPES.RANGE },
          ]}
          className="border-bottom padding-right-regular padding-bottom-regular padding-left-regular"
        />
        <Scroller
          maxHeight={
            noMaxHeight ? undefined : maxHeight || "calc(100vh - 300px)"
          }
        >
          <div className="padding-xlarge padding-bottom-none">
            {isDemoData && (
              <Block variant="warning" className="margin-bottom-large">
                <Text
                  variant="body12Regular"
                  color={theme.colors.warning100}
                  textAlign="center"
                >
                  {_`Only data from period available in preview mode`}
                </Text>
              </Block>
            )}
            {newSelector.tab === FILTER_TYPES.PREDEFINED && (
              <Grid
                gridTemplateColumns="1fr 1fr 1fr 1fr"
                className="margin-bottom-xlarge"
                gap={8}
              >
                {PREFILTERS.map((filter) => {
                  const showHourlyRefreshTooltip =
                    filter.value === PREFILTERS_VALUES.TODAY &&
                    !isHourlyRefreshEnabled &&
                    isUserEligibleToSeeNewPricing;
                  const ChipComponent = (
                    <Chip
                      key={`filter-${filter.value}`}
                      size="small"
                      selected={
                        newSelector.predefinedSelection === filter.value
                      }
                      onClick={() => {
                        handleClickOnPrefilter(filter.value);
                      }}
                      disabled={
                        (filter.value === PREFILTERS_VALUES.TODAY &&
                          !maySeeTodayData) ||
                        (filter.isDisabledForDemo && isDemoData)
                      }
                      style={{
                        width: "100%",
                        gridColumn:
                          filter.value === PREFILTERS_VALUES.ALL
                            ? "1 / 5"
                            : undefined,
                      }}
                    >
                      {filter.label}
                      {showHourlyRefreshTooltip && (
                        <Icon
                          name="Sparkle"
                          size={12}
                          style={{ marginLeft: "3px", verticalAlign: "sub" }}
                        />
                      )}
                    </Chip>
                  );
                  if (showHourlyRefreshTooltip) {
                    return (
                      <AddonTag
                        fullWidth
                        addonName="IntradayRefresh"
                        customActionElement={ChipComponent}
                        onClickAddon={() => setAddon("hourly_refresh")}
                      />
                    );
                  }
                  return ChipComponent;
                })}
              </Grid>
            )}
            {newSelector.tab === FILTER_TYPES.RELATIVE && (
              <Grid
                gridTemplateColumns="auto 1fr 1fr"
                className="margin-bottom-xlarge"
                gap={8}
                alignItems="center"
              >
                <Text variant="BodyRegular">{_`Last|||plural`}</Text>
                <Input
                  min={1}
                  block={true}
                  type="number"
                  value={newSelector.relativeSelection.count}
                  onChange={(v) =>
                    handleRelativeFilterChange(
                      v as number,
                      newSelector.relativeSelection.granularity,
                    )
                  }
                  disabled={isDemoData}
                />
                <Select
                  block={true}
                  placeholder={
                    newSelector.relativeSelection.granularity +
                    (newSelector.relativeSelection.count === 1 ? "" : "s")
                  }
                  options={DATE_GRANULARITY_OPTIONS.map((o) => ({
                    ...o,
                    label: pluralize(
                      newSelector.relativeSelection.count,
                      o.value,
                    ),
                  }))}
                  onChange={(v) =>
                    handleRelativeFilterChange(
                      newSelector.relativeSelection.count,
                      v[0] as Granularity,
                    )
                  }
                  selected={[newSelector.relativeSelection.granularity]}
                  withRadio={false}
                  disabled={isDemoData}
                />
              </Grid>
            )}
            {(!mobileVersion || newSelector.tab === FILTER_TYPES.RANGE) && (
              <div
                className={
                  "calendar-container" +
                  (newSelector.tab === FILTER_TYPES.RANGE ? "" : " border-top")
                }
              >
                <Calendar
                  withTodayTooltip={
                    !isHourlyRefreshEnabled && isUserEligibleToSeeNewPricing
                  }
                  minDate={minDateToUse}
                  maxDate={maxDateToUse}
                  width="100%"
                  selectionRange={newSelector.rangeSelection}
                  weekStartsOn={
                    getUserTenantSetting("weekstart", "sunday") === "sunday"
                      ? 0
                      : 1
                  }
                  onSelectRange={(range) => {
                    setNewCompareRange(
                      getCompareDateRange(
                        range,
                        newComparePeriod,
                        newCompareRange,
                        newMatchingDayOfWeek,
                        granularity,
                      ),
                    );
                    setNewSelector((s) => ({
                      ...s,
                      tab: FILTER_TYPES.RANGE,
                      rangeSelection: range,
                    }));
                  }}
                  renderTodayTooltip={
                    !isHourlyRefreshEnabled
                      ? (children) => (
                          <AddonTag
                            addonName="IntradayRefresh"
                            customActionElement={children}
                            onClickAddon={() => setAddon("hourly_refresh")}
                          />
                        )
                      : undefined
                  }
                />
              </div>
            )}
            {allowCompare && (
              <div className="margin-top-large">
                <ComparisonTypeSelector
                  matchingDaysOfWeek={newMatchingDayOfWeek}
                  onDayOfWeekMatchingChange={setNewMatchingDayOfWeek}
                  granularity={granularity}
                  range={newSelector.rangeSelection}
                  compareRange={newCompareRange}
                  onCompareRangeChange={setNewCompareRange}
                  period={newComparePeriod}
                  onPeriodTypeChange={setNewComparePeriod}
                />
              </div>
            )}
          </div>
          <div className="padding-xlarge padding-bottom-none padding-top-none">
            {areDatesOutsideOfBounds &&
              (isDemoData ? (
                <Block
                  variant="warning"
                  className="margin-bottom-large margin-top-large"
                >
                  <Text
                    variant="body12Regular"
                    color={theme.colors.warning100}
                    textAlign="center"
                  >
                    {_`Please select both actual and compare range inside the available demo data`}
                  </Text>
                </Block>
              ) : isFreeOrTrial ? (
                <div className="subscription-needed">
                  <Grid gridTemplateColumns="auto 1fr" gap={8}>
                    <div className="rocket-icon">
                      <img src="/img/emojis/rocket.png" alt="" />
                    </div>
                    <div>
                      {_`Please upgrade to view more than one year of historical data, including the comparison timeframe`}
                    </div>
                  </Grid>
                  <Button
                    color="warning"
                    block={true}
                    className="margin-top-regular"
                    onClick={() => setIsShowingPlans(true)}
                  >
                    {_`Upgrade now`}
                  </Button>
                </div>
              ) : null)}
            {allowCompare && (
              <Text
                variant="Tiny"
                textAlign="center"
                className="margin-top-large"
              >
                {_`Compare the period`} {_`from|||time`}{" "}
                <Text variant="Tiny" inline color={legacyTheme.colors.primary1}>
                  {newSelector.rangeSelection.start.format(
                    `dddd DD MMMM${
                      newSelector.rangeSelection.start.isSame(
                        newSelector.rangeSelection.end,
                        "year",
                      )
                        ? ""
                        : " YYYY"
                    }`,
                  )}{" "}
                  {_`to|||time`}{" "}
                  {newSelector.rangeSelection.end.format("dddd DD MMMM YYYY")}
                </Text>{" "}
                {_`to the period|||comparison`} {_`from|||time`}{" "}
                <Text variant="Tiny" inline color={legacyTheme.colors.primary5}>
                  {newCompareRange.start.format(
                    `dddd DD MMMM${
                      newCompareRange.start.isSame(newCompareRange.end, "year")
                        ? ""
                        : " YYYY"
                    }`,
                  )}{" "}
                  {_`to|||time`}{" "}
                  {newCompareRange.end.format("dddd DD MMMM YYYY")}
                </Text>
              </Text>
            )}
            {warnTooManyDatapoints && (
              <div className="subscription-needed">
                <div>
                  {_`As your current date range and granularity are quite large, you may experience performance issues in your reports. We suggest either reducing your date range or a viewing a higher granularity (i.e. By Year, By Quarter).`}
                </div>
              </div>
            )}
          </div>
        </Scroller>

        <div className="padding-xlarge padding-bottom-none padding-top-none border-top">
          <Button
            onClick={handleApply}
            className="margin-top-large"
            color="primary"
            label={_`Apply`}
            block={true}
            disabled={areDatesOutsideOfBounds}
          />
          {additionalFooterActions}
        </div>
        {footerInfoComponent ? (
          <FooterInfo>{footerInfoComponent}</FooterInfo>
        ) : (
          <></>
        )}
      </div>
    </OutsideClickHandler>
  );
}
