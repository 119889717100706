import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { CriteoConnectMessage } from "../pages/connectors/popups/connect/CriteoConnectMessage";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";

const openTab = (e: React.MouseEvent<HTMLAnchorElement>) => {
  e.preventDefault();
  e.stopPropagation();
  window
    .open(
      "https://intercom.help/polar-app/en/articles/5789375-how-can-i-connect-criteo-to-my-account",
      "_blank",
    )
    ?.focus();
};

registerFrontendIntegration({
  category: INTEGRATION_CATEGORIES.advertising,
  key: "criteo",
  image: "Criteo",
  dependsOnAccounts: true,
  backgroundColor: theme.customColors.bingBg,
  borderColor: theme.customColors.bingBorder,
  label: "Criteo",
  isNewIntegrationService: true,
  setupInstructionsSection: CriteoConnectMessage,
  paramsDescriptionOverride: {
    client_secret: (
      <>
        {_`To learn how to find your Client ID and Client Secret, you can read`}{" "}
        <a href="/#" onClick={openTab}>
          {_`this article`}
        </a>
      </>
    ),
  },
  get description() {
    return _`Online advertising platform`;
  },
  provider: "fivetran",
});
