import {
  NotificationMessage,
  Space,
  Text,
  theme,
} from "../../../../icecube-ux";
import { _, _node } from "../../../../languages/helper";

const { primary110, text110, primary100 } = theme.colors;

export const RechargeConnectMessage = () => (
  <>
    <NotificationMessage
      variant="long"
      style={{ alignItems: "start", padding: "12px" }}
      textContent={
        <>
          <Text variant="body12Regular" color={text110}>
            <Text variant="body12SemiBold" color={primary110}>
              {_`Important`}
            </Text>
            <Text variant="body12Regular" color={text110} inline>
              {_`If you have an error while connecting your Recharge account to the app, follow these steps.`}{" "}
              {_node`Read the detailed article ${[
                "link",
                <a
                  href="https://intercom.help/polar-app/en/articles/5814352-how-can-i-connect-recharge-to-my-account"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Intercom help"
                >
                  <Text
                    variant="body12Regular"
                    color={primary100}
                    inline
                    underline
                  >
                    {_`here|||link`}
                  </Text>
                </a>,
              ]}.`}{" "}
            </Text>
          </Text>
          <Text variant="body12Regular" color={text110}>
            <Text
              style={{ marginTop: "12px" }}
              variant="body12SemiBold"
              color={primary110}
            >
              {" "}
              {_`Step ${["number", "1"]}`}{" "}
            </Text>
            <span>
              {_node`In your Recharge settings, make sure you are the store owner or that you have ${[
                "Accounts",
                <span className="italic">Accounts</span>,
              ]} and ${[
                "APITokens",
                <span className="italic">API Tokens</span>,
              ]} access. More details on how to do this ${[
                "link",
                <a
                  href="https://support.rechargepayments.com/hc/en-us/articles/4406131208343-Enabling-Recharge-user-and-account-permissions-#h_01FCVB0K6CWSPX9N8HYF1WT5KY"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="User permissions"
                >
                  <Text
                    variant="body12Regular"
                    color={primary100}
                    inline
                    underline
                  >
                    {_`here|||link`}
                  </Text>
                </a>,
              ]}.`}{" "}
            </span>
          </Text>

          <Text variant="body12Regular" color={text110}>
            <Text
              style={{ marginTop: "12px" }}
              color={primary110}
              variant="body12SemiBold"
            >
              {" "}
              {_`Step ${["number", "2"]}`}{" "}
            </Text>
            <span>
              {_node`Login to your Recharge Admin Portal, go to ${[
                "Apps",
                <span className="italic">Apps</span>,
              ]} then ${[
                "APITokens",
                <span className="italic">API Tokens</span>,
              ]} and create an Admin API Token. In the ${[
                "Permission",
                <span className="italic">Permission</span>,
              ]} section, select the ${[
                "Readaccess",
                <span className="italic">Read Access</span>,
              ]} scope for each permission, and save.`}{" "}
            </span>
          </Text>
          <Text variant="body12Regular" color={text110}>
            <Text
              style={{ marginTop: "12px" }}
              color={primary110}
              variant="body12SemiBold"
            >
              {" "}
              {_`Step ${["number", "3"]}`}{" "}
            </Text>
            <span>
              {" "}
              {_node`Navigate back to your Polar Connectors, copy and paste the API token into your connector, and select the associated Shopify store from the drop-down list.`}
            </span>
          </Text>
        </>
      }
    />
    <Space />
  </>
);
