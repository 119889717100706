import React from "react";

import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { KlaviyoConnectMessage } from "../pages/connectors/popups/connect/KlaviyoConnectMessage";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";

const openTab = (e: React.MouseEvent<HTMLAnchorElement>) => {
  e.preventDefault();
  e.stopPropagation();
  window
    .open(
      "https://intercom.help/polar-app/en/articles/5635048-i-need-to-add-klaviyo-to-my-account",
      "_blank",
    )
    ?.focus();
};

registerFrontendIntegration({
  key: "klaviyo",
  category: INTEGRATION_CATEGORIES.marketing,
  image: "Klaviyo",
  dependsOnAccounts: false,
  isNewIntegrationService: true,
  backgroundColor: theme.customColors.klaviyoBg,
  borderColor: theme.customColors.klaviyoBorder,
  label: "Klaviyo",
  get description() {
    return _`E-mail service that lets you deliver amazing experiences`;
  },
  adPlatformChannelName: "Klaviyo",
  utmGuide: "6868158",
  setupInstructionsSection: KlaviyoConnectMessage,
  paramsDescriptionOverride: {
    api_key: (
      <>
        {_`To learn how to find your API key, please read`}{" "}
        <a href="/#" onClick={openTab}>
          {_`this article`}
        </a>
      </>
    ),
  },
  provider: "fivetran",
});
