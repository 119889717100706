import React, { useCallback, useEffect, useRef, useState } from "react";

import useOutsideClickHandler from "../hooks/useOutsideClickHandler";

export type TooltipDirection = "top" | "bottom" | "right" | "left";

interface TooltipHookProps {
  direction: TooltipDirection;
  persistOnClick: boolean;
  isInteractive: boolean;
  delay?: number;
  mouseLeaveDelay?: number;
}

const ARROW_MARGIN = 4;
const MIN_WINDOW_MARGIN = 4;

export default function useTooltip({
  direction,
  persistOnClick,
  isInteractive,
  delay,
  mouseLeaveDelay,
}: TooltipHookProps) {
  const [scroll, setScroll] = useState(1);
  const [anchorNode, setAnchorNode] = useState<HTMLDivElement | null>(null);
  const [tooltipNode, setTooltipNode] = useState<HTMLDivElement | null>(null);
  const [shown, realSetShown] = useState(false);
  const delayTimeoutsRef = useRef<
    {
      value: boolean;
      timeout: NodeJS.Timeout;
    }[]
  >([]);
  const setShown = (value: boolean) => {
    delayTimeoutsRef.current.forEach((timeout) => {
      if (value !== timeout.value) {
        clearTimeout(timeout.timeout);
      }
    });
    if (delay && value === true) {
      delayTimeoutsRef.current = [
        { value, timeout: setTimeout(() => realSetShown(value), delay) },
        ...delayTimeoutsRef.current,
      ];
    } else {
      realSetShown(value);
    }
  };
  const [tooltipContentAlignement, setTooltipContentAlignement] = useState<
    "left" | "center" | "right"
  >("left");
  const persisted = useRef(false);
  const [arrowStyle, setArrowStyle] = useState<React.CSSProperties | null>({});
  const [tooltipStyle, setTooltipStyle] = useState<React.CSSProperties | null>(
    {},
  );
  const [windowDimension, setWindowDimension] = useState({
    height: 0,
    width: 0,
  });
  let mouseLeaveTimeout: NodeJS.Timeout | null = null;
  const anchorRefCallback = useCallback((element: HTMLDivElement) => {
    setAnchorNode(element);
  }, []);

  useOutsideClickHandler(() => {
    setShown(false);
    persisted.current = false;
  }, anchorNode);

  const tooltipRefCallback = useCallback((element: HTMLDivElement) => {
    setTooltipNode(element);
  }, []);

  const handleMouseEnter = () => {
    if (!isInteractive) {
      mouseLeaveTimeout && clearTimeout(mouseLeaveTimeout);
      setShown(true);
    }
  };

  const handleMouseMove = () => {
    mouseLeaveTimeout && clearTimeout(mouseLeaveTimeout);
    setShown(true);
  };

  const handleMouseLeave = () => {
    if (!persistOnClick || (persistOnClick && !persisted.current)) {
      if (mouseLeaveDelay !== undefined) {
        mouseLeaveTimeout = setTimeout(() => {
          setShown(false);
        }, mouseLeaveDelay);
        return;
      }
      setShown(false);
    }
  };

  const hide = () => {
    setShown(false);
  };

  const handleMouseClick = () => {
    if (persistOnClick) {
      persisted.current = !persisted.current;
      setShown(persisted.current);
    }
  };

  const getRealDirection = () => {
    if (!anchorNode || !tooltipNode) {
      return direction;
    }

    const anchorRect = anchorNode.getBoundingClientRect();
    const tooltipRect = tooltipNode.getBoundingClientRect();

    const canBePlacedOnLeft =
      anchorRect.x - tooltipRect.width - ARROW_MARGIN > MIN_WINDOW_MARGIN;
    const canBePlacedOnRight =
      anchorRect.x + anchorRect.width + tooltipRect.width + ARROW_MARGIN <
      windowDimension.width - MIN_WINDOW_MARGIN;
    const canBePlacedOnTop =
      anchorRect.y - tooltipRect.height - ARROW_MARGIN > MIN_WINDOW_MARGIN;
    const canBePlacedOnBottom =
      anchorRect.y + anchorRect.height + tooltipRect.height + ARROW_MARGIN <
      windowDimension.height - MIN_WINDOW_MARGIN;
    const availabilities = [
      canBePlacedOnLeft ? "left" : null,
      canBePlacedOnRight ? "right" : null,
      canBePlacedOnTop ? "top" : null,
      canBePlacedOnBottom ? "bottom" : null,
    ].filter((direction) => direction !== null) as TooltipDirection[];
    // when none of the direction is available, return the requested direction
    if (availabilities.length === 0) {
      return direction;
    }
    // if the requested direction is available, return it
    if (availabilities.includes(direction)) {
      return direction;
    }
    // if the requested direction is not available, return the first available direction
    return availabilities[0];
  };

  useEffect(() => {
    if (!anchorNode || !tooltipNode) {
      return;
    }

    const anchorRect = anchorNode.getBoundingClientRect();
    const tooltipRect = tooltipNode.getBoundingClientRect();

    const tooltipStyle: {
      left?: number;
      top?: number;
      right?: number;
      bottom?: number;
    } = {};
    const arrowStyle: {
      left?: number;
      top?: number;
      transform?: string;
    } = {};
    const realDirection = getRealDirection();
    const alignement = ["top", "bottom"].includes(realDirection)
      ? "center"
      : realDirection === "left"
      ? "right"
      : "left";

    switch (realDirection) {
      case "top":
        tooltipStyle.left = anchorRect.x + anchorRect.width / 2 - 200;
        tooltipStyle.top = anchorRect.y - tooltipRect.height - ARROW_MARGIN;
        arrowStyle.left = anchorRect.x + anchorRect.width / 2;
        arrowStyle.top =
          anchorRect.y - tooltipRect.height - ARROW_MARGIN + tooltipRect.height;
        break;
      case "bottom":
        tooltipStyle.left = anchorRect.x + anchorRect.width / 2 - 200;
        tooltipStyle.top = anchorRect.y + anchorRect.height + ARROW_MARGIN;
        arrowStyle.transform = "scaleY(-1)";
        arrowStyle.left = anchorRect.x + anchorRect.width / 2;
        arrowStyle.top = anchorRect.y + anchorRect.height + 0.5;
        break;
      case "left":
        tooltipStyle.left = anchorRect.x - 400 - ARROW_MARGIN;
        tooltipStyle.top =
          anchorRect.y + anchorRect.height / 2 - tooltipRect.height / 2;
        arrowStyle.transform = "rotate(-90deg)";
        arrowStyle.left = anchorRect.x - ARROW_MARGIN - 2;
        arrowStyle.top =
          anchorRect.y + anchorRect.height / 2 - ARROW_MARGIN / 2;
        break;
      case "right":
        tooltipStyle.left = anchorRect.x + anchorRect.width + ARROW_MARGIN;
        tooltipStyle.top =
          anchorRect.y + anchorRect.height / 2 - tooltipRect.height / 2;
        arrowStyle.transform = "rotate(90deg)";
        arrowStyle.left = anchorRect.x + anchorRect.width - 2;
        arrowStyle.top =
          anchorRect.y + anchorRect.height / 2 - ARROW_MARGIN / 2;
        break;
    }

    setTooltipContentAlignement(alignement);
    setTooltipStyle(tooltipStyle);
    setArrowStyle(arrowStyle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    anchorNode,
    tooltipNode,
    windowDimension.height,
    windowDimension.width,
    shown,
    window.scrollY,
    scroll,
  ]);

  useEffect(() => {
    const handleScroll = () => {
      setScroll((s) => s + 1);
    };
    const handleResize = () => {
      setWindowDimension({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll, true);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { x, y } = anchorNode?.getBoundingClientRect() || { x: 0, y: 0 };
  return {
    anchorRefCallback,
    tooltipRefCallback,
    x,
    y,
    shown,
    tooltipStyle,
    arrowStyle,
    handleMouseMove,
    handleMouseLeave,
    handleMouseEnter,
    handleMouseClick,
    hide,
    tooltipContentAlignement,
    realDirection: getRealDirection(),
  };
}
