const connectorIconNames = {
  Amazon: "Amazon",
  Awin: "Awin",
  Attentive: "Attentive",
  Bing: "Bing",
  Benchmarks: "Benchmarks",
  Criteo: "Criteo",
  Facebook: "Facebook",
  Fairing: "Fairing",
  Gmail: "Gmail",
  GoogleAds: "GoogleAds",
  GoogleAdManager: "GoogleAdManager",
  GoogleAnalytics: "GoogleAnalytics",
  GoogleSearchConsole: "GoogleSearchConsole",
  GoogleSheet: "GoogleSheet",
  GoogleSheets: "GoogleSheets",
  Google: "Google",
  Gorgias: "Gorgias",
  Impact: "Impact",
  Instagram: "Instagram",
  InstagramBusiness: "InstagramBusiness",
  Klaviyo: "Klaviyo",
  Mailchimp: "Mailchimp",
  Mailjet: "Mailjet",
  Omnisend: "Omnisend",
  Outreach: "Outreach",
  Paypal: "Paypal",
  Pinterest: "Pinterest",
  Polar: "Polar",
  PolarPixel: "PolarPixel",
  Postscript: "Postscript",
  Prestashop: "Prestashop",
  Recharge: "Recharge",
  ShipStation: "ShipStation",
  Snapchat: "Snapchat",
  Shopify: "Shopify",
  Slack: "Slack",
  Taboola: "Taboola",
  Tiktok: "Tiktok",
  TwitterAds: "TwitterAds",
  WordPress: "WordPress",
  Yotpo: "Yotpo",
  ZohoCampaigns: "ZohoCampaigns",
  ZohoCRM: "ZohoCRM",
  Direct: "Direct",
} as const;

export type ConnectorIconNames = keyof typeof connectorIconNames;

export const isValidConnectorIcon = (v: unknown): v is ConnectorIconNames => {
  return !!connectorIconNames[v as ConnectorIconNames];
};
