import React, { LazyExoticComponent } from "react";

import { IconSVGProps } from "../Icon/types";

import { ConnectorIconNames } from "./types";

const ConnectorsIconSVG: Record<
  ConnectorIconNames,
  LazyExoticComponent<(props: IconSVGProps) => React.ReactElement>
> = {
  Awin: React.lazy(() => import("./svg/Awin")),
  Amazon: React.lazy(() => import("./svg/Amazon")),
  Attentive: React.lazy(() => import("./svg/Attentive")),
  Benchmarks: React.lazy(() => import("./svg/Benchmarks")),
  Bing: React.lazy(() => import("./svg/Bing")),
  Criteo: React.lazy(() => import("./svg/Criteo")),
  Direct: React.lazy(() => import("./svg/Direct")),
  Facebook: React.lazy(() => import("./svg/Facebook")),
  Gmail: React.lazy(() => import("./svg/Gmail")),
  GoogleAds: React.lazy(() => import("./svg/GoogleAds")),
  GoogleAdManager: React.lazy(() => import("./svg/GoogleAdManager")),
  GoogleAnalytics: React.lazy(() => import("./svg/GoogleAnalytics")),
  GoogleSearchConsole: React.lazy(() => import("./svg/GoogleSearchConsole")),
  GoogleSheet: React.lazy(() => import("./svg/GoogleSheet")),
  Google: React.lazy(() => import("./svg/Google")),
  Gorgias: React.lazy(() => import("./svg/Gorgias")),
  Impact: React.lazy(() => import("./svg/Impact")),
  Instagram: React.lazy(() => import("./svg/Instagram")),
  InstagramBusiness: React.lazy(() => import("./svg/InstagramBusiness")),
  Klaviyo: React.lazy(() => import("./svg/Klaviyo")),
  Mailchimp: React.lazy(() => import("./svg/Mailchimp")),
  Mailjet: React.lazy(() => import("./svg/Mailjet")),
  Pinterest: React.lazy(() => import("./svg/Pinterest")),
  Polar: React.lazy(() => import("./svg/Polar")),
  PolarPixel: React.lazy(() => import("./svg/PolarPixel")),
  Prestashop: React.lazy(() => import("./svg/Prestashop")),
  Recharge: React.lazy(() => import("./svg/Recharge")),
  Shopify: React.lazy(() => import("./svg/Shopify")),
  Slack: React.lazy(() => import("./svg/Slack")),
  Taboola: React.lazy(() => import("./svg/Taboola")),
  ShipStation: React.lazy(() => import("./svg/ShipStation")),
  Snapchat: React.lazy(() => import("./svg/Snapchat")),
  Tiktok: React.lazy(() => import("./svg/Tiktok")),
  TwitterAds: React.lazy(() => import("./svg/TwitterAds")),
  WordPress: React.lazy(() => import("./svg/WordPress")),
  Yotpo: React.lazy(() => import("./svg/Yotpo")),
  ZohoCRM: React.lazy(() => import("./svg/ZohoCRM")),
  ZohoCampaigns: React.lazy(() => import("./svg/ZohoCampaigns")),
  GoogleSheets: React.lazy(() => import("./svg/GoogleSheets")),
  Omnisend: React.lazy(() => import("./svg/Omnisend")),
  Outreach: React.lazy(() => import("./svg/Outreach")),
  Paypal: React.lazy(() => import("./svg/Paypal")),
  Postscript: React.lazy(() => import("./svg/Postscript")),
  Fairing: React.lazy(() => import("./svg/Fairing")),
};

export { ConnectorsIconSVG };
