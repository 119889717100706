import classNames from "classnames";

import { asPercentagePointsInRange } from "../../utils/fractionUtils";
import { ClassAndStyleProps, InteractionEvents } from "../shared";

import {
  StyledProgressBar,
  StyledProgressBarWrapper,
} from "./ProgressBar.styled";

export type ProgressBarProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    /**
     * A fraction between 0 and 1
     */
    progress: number;
    width?: string;
    variant?: "success" | "warning" | "primary" | "default";
    size?: "large" | "small";
    withBorder?: boolean;
    color?: string;
    borderRadius?: "large" | "small";
    floor?: number;
  };

export default function ProgressBar({
  className,
  style,
  progress,
  width = "100%",
  variant = "default",
  size = "large",
  withBorder,
  color,
  borderRadius = "small",
  floor = 0,
  ...interactionEvents
}: ProgressBarProps) {
  const progressAsPercent = Math.max(
    asPercentagePointsInRange(progress),
    floor,
  );

  return (
    <StyledProgressBarWrapper
      {...interactionEvents}
      className={classNames(className)}
      width={width}
      style={style}
      variant={variant}
      size={size}
      withBorder={withBorder}
      borderRadius={borderRadius}
    >
      <StyledProgressBar
        role="progressbar"
        aria-valuenow={progressAsPercent}
        aria-valuemin={0}
        aria-valuemax={100}
        variant={variant}
        color={color}
      />
    </StyledProgressBarWrapper>
  );
}
